<script setup lang="ts">
    import { ref, type Ref, inject, computed, onMounted } from 'vue';
    import localStorageHelper from 'o365.modules.StorageHelpers.ts';
    
    const props = defineProps(['dataObject']);

    const currentPage = <Ref<number>>inject('currentPage');
    const isOnLastPage = <Ref<Function>>inject('isOnLastPage');
    const resetCurrentPage = <Ref<Function>>inject('resetCurrentPage');
    const navigatePrevPage = <Ref<Function>>inject('navigatePrevPage');
    const navigateNextPage = <Ref<Function>>inject('navigateNextPage');

    const isLoadingRows = ref(false);
    const isLoadingRows2 = ref(false);
    const localStoragePageSizeKey = `${props.dataObject.id}_pagesize`;
    const initialPageSize = ref(props.dataObject.dynamicLoading.pageSize);
    const currentPageSize = ref(parseInt(localStorageHelper.getItem(localStoragePageSizeKey, undefined, props.dataObject.dynamicLoading.pageSize), 10));

    const currentItemLower = computed(() => {
        const pageSize = props.dataObject.recordSource.maxRecords;
        
        return (currentPage.value * pageSize) + 1;
    });

    const currentItemHigher = computed(() => {
        const { dataLength } = props.dataObject.dynamicLoading;

        if (isOnLastPage.value) {
            return dataLength;
        }

        return (currentPage.value + 1) * currentPageSize.value;
    });

    const totalItems = computed(() => {
        return props.dataObject.rowCount;
    });

    const loadRowCount = (): void => {
        isLoadingRows.value = true;
        
        props.dataObject.recordSource.loadRowCount({ timeout: 5000 }).then(() => {
            isLoadingRows.value = false;
        });
    }

    async function reload(){
        isLoadingRows2.value = true;
        await props.dataObject.load();
        isLoadingRows2.value = false;
    }

    const updatePageSize = (e: Event): void => {
        const target = <HTMLInputElement>e?.target;
        let newPageSize = parseInt(target.value, 10);

        if (isNaN(newPageSize) || newPageSize < 1) {
            newPageSize = 1;
            target.value = '1';
        }

        if (newPageSize > 50) {
            newPageSize = 50;
            target.value = '50';
        }

        resetCurrentPage();
        currentPageSize.value = newPageSize;
        props.dataObject.recordSource.maxRecords = newPageSize;
        localStorageHelper.setItem(localStoragePageSizeKey, newPageSize);
        
        props.dataObject.load();
    }

    const resetPageSize = (): void => {
        currentPageSize.value = initialPageSize.value;
        props.dataObject.recordSource.maxRecords = initialPageSize.value;
        localStorageHelper.setItem(localStoragePageSizeKey, initialPageSize.value);
        
        props.dataObject.load();
    }

    onMounted(() => {
        props.dataObject.recordSource.maxRecords = currentPageSize.value;
        props.dataObject.load();
    });
</script>

<template>
    <span class="d-flex align-items-center fw-normal">
        <button class="btn btn-sm btn-link ps-0" :disabled="props.dataObject.dynamicLoading.currentPage === 0" @click="navigatePrevPage">
            <i class="bi bi-chevron-left"></i>
        </button>
        
        <button class="btn btn-sm btn-link" :disabled="isOnLastPage" @click="navigateNextPage">
            <i class="bi bi-chevron-right"></i>
        </button>

        <template v-if="dataObject.data.length > 0">
            <span class="d-flex align-items-center gap-2 small">
                <span v-if="currentPageSize > 1">{{currentItemLower}}-{{currentItemHigher}}</span>
                <span v-if="currentPageSize === 1">{{currentItemLower}}</span>
                
                <span>/</span>
                
                <template v-if="isLoadingRows">
                    <span class="spinner-border spinner-border-sm text-primary"></span>
                </template>

                <template v-else>
                    <button v-if="totalItems === null" class="btn btn-sm btn-link p-0">
                        <i class="bi bi-question-circle" @click="loadRowCount"></i>
                    </button>
                    
                    <span v-else>{{totalItems}}</span>
                </template>
            </span>
        </template>

        <template v-else>
            <span class="small">{{$t('No rows found')}}</span>
        </template>

        <span class="dropdown">
            <button class="btn btn-sm btn-link pe-0" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-list"></i>
            </button>

            <span class="dropdown-menu align-items-center gap-2 p-2">
                <span class="text-nowrap">{{$t('Page size')}}</span>
                
                <input type="number" class="form-control form-control-sm" style="min-width:50px;" :value="currentPageSize" @blur="updatePageSize" @keyup.enter="updatePageSize" />
                
                <button v-if="currentPageSize !== initialPageSize" class="btn btn-sm btn-link p-0">
                    <i class="bi bi-arrow-counterclockwise" :title="$t('Reset to default')" @click="resetPageSize"></i>
                </button>
            </span>
        </span>
        
        <template v-if="isLoadingRows2"> 
            <button class="btn btn-sm btn-link p-0 ms-2 pt-1">
                <span class="spinner-border spinner-border-sm text</button>-primary"></span>
            </button>
        </template>
        <template v-else>
            <button class="btn btn-sm btn-link p-0 ms-2 pt-1">
                <i class="bi bi-arrow-clockwise fs-6 " @click="reload"></i>
            </button>
        </template>
    </span>
</template>

<style scoped>
    .dropdown-menu.show {
        display: flex;
    }
</style>